<template>
    <div>
        <v-layout>
            <div class="boxapp">
                <!-- boxapp head -->
                <div class="boxapp-head">
                    <a href="#" @click="$router.go(-1)">
                        <faIcon type="fal" name="long-arrow-left" size="24" colorFont="#cfd2dd" />
                    </a>
                    <h1>Projeto de Voluntariado</h1>
                </div>

                <!-- boxapp body -->
                <div class="box-main">
                    <StepsVolunteering :page-options="pageOptions" :volunteering="project" />

                    <div class="section list-view">
                        <!-- section head -->
                        <div class="head">
                            <h5>PASSO 3</h5>
                        </div>
                        <v-flex class="xs12 pr-2">
                            <label class="text-gray">Sobre</label>
                            <vue-editor :editor-toolbar="customToolbar" v-model="project.about" @input="checkForm()"/>
                        </v-flex>
                    </div>
                    <BorderSeparation />
                    <div>
                        <v-layout row wrap>
                            <h3>Beneficiários</h3>
                            <v-flex class="xs12 pr-2">
                                <InputMoney
                                    textLabel="Número de beneficiários"
                                    place-holder="2.500"
                                    id="beneficiaries-input"
                                    v-model="project.beneficiaries"
                                    :precision="0"
                                    @input="checkForm()"
                                    required
                                    prefix
                                />
                            </v-flex>
                        </v-layout>
                    </div>
                </div>
            </div>
            <div class="box-help-spacer"></div>
            <HelpBox :text="text" />
        </v-layout>
        <div class="footer-step" style="display: flex;">
            <v-btn
                flat
                round
                color="white"
                class="btn-default flex-inherit px-5"
                large
                v-model="pageOptions"
                @click="previousPage"
            >Voltar</v-btn>
            <div class="btn-right">
                <v-btn
                    flat
                    round
                    color="white"
                    class="btn-primary flex-inherit px-5"
                    large
                    v-model="pageOptions"
                    @click="nextPage"
                >Próximo</v-btn>
            </div>
        </div>
        <AucAlert
            ref="coverageAlertComponent"
            title="Você tem certeza que deseja excluir essa abrangência?"
            confirmButtonText="Sim"
            cancelButtonText="Não"
            @confirm="removeCoverage(selectedCoverage)"
        />
        <AucAlert
            ref="donationProductAlertComponent"
            title="Você tem certeza que deseja desativar esse produto de doação?"
            confirmButtonText="Sim"
            cancelButtonText="Não"
            @confirm="disableDonationProduct(selectedDonationProduct)"
        />
    </div>
</template>

<script>
import FooterStep from "@/components/FooterStep.vue";
import HelpBox from "@/components/HelpBox.vue";
import ValueRange from "@/components/ValueRange.vue";
import FileImage from "@/components/FileImage.vue";
import Coverage from "@/scripts/models/coverage.model.js";
import Project from "@/scripts/models/project.model";
import ProjectService from "@/scripts/services/project.service";
import VolunteeringService from "@/scripts/services/volunteering.service";
import VolunteeringAttributeOfferService from "@/scripts/services/volunteeringAttributeOffer.service";
import { VueEditor } from "vue2-editor";
import { GetProjectTypeName } from "@/scripts/models/enums/projectTypes.enum.js";
import ValidatableComponent from "@/components/base/ValidatableComponent.vue";
import AucAlert from "@/components/AucAlert.vue";
import { Routes } from "@/scripts/models/enums/routes.enum.js";
import { HelpBoxText } from "@/scripts/models/enums/helpBoxText.enum.js";
import FileService from "@/scripts/services/file.service";
import { GetAllRequiredFields } from "@/scripts/helpers/fields-checker.js"
import CommonHelper from "@/scripts/helpers/common.helper";

export default {
    extends: ValidatableComponent,
    components: {
        HelpBox,
        FooterStep,
        ValueRange,
        FileImage,
        AucAlert,
        VueEditor,
    },

    props: ["pageOptions"],
    data() {
        return {
            text: HelpBoxText.ProjectStep3,
            project: new Project(),
            projectService: new ProjectService(),
            volunteeringService: new VolunteeringService(),
            volunteeringAttributeOfferService: new VolunteeringAttributeOfferService(),
            coverageModel: new Coverage(),
            routes: Routes,
            autocompleteOptions: {
                componentRestrictions: { country: "br" },
            },
            getProjectTypeName: GetProjectTypeName,
            newProduct: false,
            projectValidationStatusIndex: 2,
            showCoverageValidationMessage: false,
            selectedCoverage: null,
            textarea: "",
            showFieldProduct: false,
            dialog: false,
            selectedDonationProduct: null,
            fileService: new FileService(),
            customToolbar: [
                ["bold", "italic", "underline"],
                [{ list: "ordered" }, { list: "bullet" }],
            ],
            breadcrumbLevels: [
                { text: "Início" },
                { text: "Projeto de Voluntariado" },
                { text: "Passo 3" },
            ],
            requiredFields: [],
            formIsOk: false
        };
    },
    async mounted() {
        this.requiredFields = await GetAllRequiredFields();
        this.requiredFields.push('ql-editor')
    },
    created() {
        if (!this.$route.query.volunteeringId) {
            CommonHelper.swal("Projeto não informado", "O projeto não foi informado");
            this.$router.go(-1);
        }
        this.loadProject(this.$route.query.volunteeringId);
        this.$emit("changeLevels", this.breadcrumbLevels);
        this.pageOptions.currentPage = 3;
        this.validationMethod = this.validate;
        this.onValidationSuccessMethod = this.setValidStep;
        this.onValidationFailMethod = this.setInvalidStep;
    },
    methods: {
        previousPage() {
            this.$router.push({
                name: this.routes.app.VolunteeringStep2,
                query: {
                    volunteeringId: this.project.id,
                },
            });
        },
        nextPage() {
            this.volunteeringService.save(this.project).then((data) => {
                this.$router.push({
                    name: this.routes.app.VolunteeringGallery,
                    query: {
                        volunteeringId: data.id,
                    },
                });
            });
        },
        loadProject(id) {
            this.volunteeringService.findById(id).then((data) => {
                this.project = data;
                if (data.faviconFile == null) this.project.faviconFile = new File();

                this.volunteeringAttributeOfferService
                    .findByVolunteeringId(id)
                    .then(async (volunteeringAttributeOffers) => {
                        this.project.volunteeringAttributeOffers = volunteeringAttributeOffers;
                        await this.checkForm()
                    });
            });
        },
        // Validation
        setValidStep() {
            this.$set(this.project.stepsValidationStatus, "step2", true);
        },
        showFailMessage() {
            if (!this.project.coverageList || this.project.coverageList.length == 0) {
                this.$store.commit(
                    "SET_SNACKBAR_MESSAGE",
                    "Você não preencheu todos os campos"
                );
            }
        },
        setInvalidStep() {
            this.$set(this.project.stepsValidationStatus, "step2", false);
        },
        validateStep() {
            let valid = this.isValid(false);
            if (valid) this.setValidStep();
            else this.setInvalidStep();

            this.projectService.save(this.project);
        },
        validate(performMethods) {
            let valid = true;
            this.showCoverageValidationMessage = false;
            if (!this.project.coverageList || this.project.coverageList.length == 0) {
                valid = false;
                if (performMethods) this.showCoverageValidationMessage = true;
            }
            if (!valid && performMethods) {
                this.showFailMessage();
            }
            return valid;
        },
        async checkForm() {
            let requiredFieldsCounter = 0
            for (const item of this.requiredFields) {
                let element = document.getElementById(item)
                let value = null
                if (!element) {
                    element = document.getElementsByClassName(item)
                    element = element[0].children[0]
                    value = element.innerText
                } else {
                    value = element.value
                }
                
                if (value !== '' && value !== null && value !== '\n' && value !== '0' && value !== 0) requiredFieldsCounter++

                if (requiredFieldsCounter === this.requiredFields.length) this.formIsOk = true
                else this.formIsOk = false
            }
        }
    },
};
</script>